.header {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding: 12px;
  position: relative;
  align-items: center;
}
.menu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.menu-icon {
  font-size: 24px;
}
.mobile-menu {
  display: none;
}
.web-menu {
  display: flex;
}
.logo {
  display: flex;
  font-size: 36px;
  color: black;
  font-weight: 800;
}
@media only screen and (max-width: 720px) {
  /* for laptop */
  .mobile-menu {
    display: flex;
  }
  .web-menu {
    display: none;
  }
  .logo {
    display: flex;
    font-size: 24px;
    color: black;
    font-weight: 800;
  }
}
