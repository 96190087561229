html {
    scroll-behavior: smooth;
    
}
.home{
    display:flex;
    flex-direction: column;
    width: 85% ;
    margin: auto;
}

@media only screen and (max-width: 1024px) {
/* for laptop */
.home{
    width:90%;
}
}
@media only screen and (max-width: 512px) {
/* for mobile */
.home{
    width:100%;
}
}